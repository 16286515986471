@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';

#qa-testing-ribbon {
    position: absolute;
    display: block;
    top: 5px;
    left: 0;
    overflow: hidden;
    z-index: 9999;

    .onp-style(
        @xs: {
            position: fixed;
        },
        @small: {
            top: 11px;
            left: 120px;
        }
    );
    a {
        display: block;
        background: #000;
        color: #fff;
        text-align: center;
        font-weight: bold;
        padding: 5px 40px;
        font-size: 16px;
        line-height: 32px;
        z-index: 9999;
        width: 300px;
        box-shadow: 0 0 10px rgba(0,0,0,0.8);

        .onp-style(
            @small: {
                width: 40px;
                padding: 5px 10px;
            }
        );
        span {
            .onp-hidden(@small: true);
        }
    }
    a:hover {
        background: #ea000d;
        color: #fff;
    }
    a::before, a::after {
        content: "";
        width: 100%;
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        height: 1px;
        background: #fff;
    }
    a::after {
        bottom: 1px;
        top: auto;
    }
}
