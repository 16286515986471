#qa-testing-ribbon {
  position: absolute;
  display: block;
  top: 5px;
  left: 0;
  overflow: hidden;
  z-index: 9999;
}
@media (max-width: 767px) {
  #qa-testing-ribbon {
    top: 11px;
    left: 120px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #qa-testing-ribbon {
    top: 11px;
    left: 120px;
  }
}
@media (max-width: 767px) {
  #qa-testing-ribbon {
    position: fixed;
  }
}
#qa-testing-ribbon a {
  display: block;
  background: #000;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 5px 40px;
  font-size: 16px;
  line-height: 32px;
  z-index: 9999;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
@media (max-width: 767px) {
  #qa-testing-ribbon a {
    width: 40px;
    padding: 5px 10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #qa-testing-ribbon a {
    width: 40px;
    padding: 5px 10px;
  }
}
@media (max-width: 767px) {
  #qa-testing-ribbon a span {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #qa-testing-ribbon a span {
    display: none !important;
  }
}
#qa-testing-ribbon a:hover {
  background: #ea000d;
  color: #fff;
}
#qa-testing-ribbon a::before,
#qa-testing-ribbon a::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
  height: 1px;
  background: #fff;
}
#qa-testing-ribbon a::after {
  bottom: 1px;
  top: auto;
}
